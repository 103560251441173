// IMPORTS
import colors from "../styles/modules/_exports.module.scss";
// POLYGON
const polygon = {
  chainId: "0x89", // 137
  chainName: "Polygon Mainnet",
  nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  rpcUrls: [
    "https://polygon-rpc.com/",
    "https://rpc-mainnet.matic.network",
    "https://matic-mainnet.chainstacklabs.com",
    "https://rpc-mainnet.maticvigil.com",
    "https://rpc-mainnet.matic.quiknode.pro",
    "https://matic-mainnet-full-rpc.bwarelabs.com",
  ],
  blockExplorerUrls: ["https://polygonscan.com"],
};
const mumbai = {
  chainId: "0x13881", // 80001
  chainName: "Polygon Mumbai Testnet",
  nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  rpcUrls: [
    "https://matic-mumbai.chainstacklabs.com",
    "https://rpc-mumbai.maticvigil.com",
    "https://matic-testnet-archive-rpc.bwarelabs.com",
  ],
  blockExplorerUrls: ["https://mumbai.polygonscan.com"],
};
// ADMIN USERS
const adminUsers = [
  "0x3640e3604bb47014eb0ec2228f6afd0361b44224",
  "0x6447573f5f1b907f8f3ffb54f42ab0a96b823f78",
  "0xc37bedc169c9c35a80cc2bdf0d42a40245a6c9fb",
  "0x7e903a479ce7aecb7f00d428cb82f172e9616ddc",
];
// TICKETS
const ticketData = [
  {
    name: "Attendee",
    color: colors.yellow,
    image: require("../assets/tickets/attendee.png"),
    dropdown: require("../assets/dropdowns/attendee.png"),
  },
  {
    name: "VIP",
    color: colors.green,
    image: require("../assets/tickets/vip.png"),
    dropdown: require("../assets/dropdowns/vip.png"),
  },
  {
    name: "Ethereum Towers",
    color: colors.black,
    image: require("../assets/tickets/ethereum-towers.png"),
    dropdown: require("../assets/dropdowns/ethereum-towers.png"),
  },
  {
    name: "Crypto Holdem",
    color: colors.blue,
    image: require("../assets/tickets/crypto-holdem.png"),
    dropdown: require("../assets/dropdowns/crypto-holdem.png"),
  },
  {
    name: "NDAX",
    color: colors.lightBlue,
    image: require("../assets/tickets/ndax.png"),
    dropdown: require("../assets/dropdowns/ndax.png"),
  },
  {
    name: "Rocky Mountain Tisane",
    color: colors.black,
    image: require("../assets/tickets/rocky-mountain-tisane.png"),
    dropdown: require("../assets/dropdowns/rocky-mountain-tisane.png"),
  },
  {
    name: "Rocky Mountain Soap",
    color: colors.pinkLight,
    image: require("../assets/tickets/rocky-mountain-soap.png"),
    dropdown: require("../assets/dropdowns/rocky-mountain-soap.png"),
  },
];
// FORM DATA
const formData = [
  {
    title: "Your Name",
    placeholder: "John Smith",
    type: "text",
    required: true,
  },
  {
    title: "Email",
    placeholder: "john@smith.com",
    type: "email",
    required: true,
  },
  {
    title: "Phone",
    placeholder: "(123)-456-7890",
    type: "tel",
    required: false,
  },
  {
    title: "Twitter Handle",
    placeholder: "@john_smith",
    type: "text",
    required: true,
  },
];
// EXPORTS
export { polygon, mumbai, adminUsers, ticketData, formData };
